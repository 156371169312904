import Api from '../../api/Api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    books: [],
    message: null,
    loading: false
};

export const fetchBooksAction = createAsyncThunk('book/fetchBooksAction', async (_, { getState, dispatch, rejectWithValue }) => {
    try {
        console.log('fetchBooksAction');
        // const {
        //     eventsReducer: { value }
        // } = getState();
        // console.log('value', value);
        const { data } = await Api.get('items/books?fields=*,author_id.authors_id.*,author_id.id');
        console.log(data.data);
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const addBooksAction = createAsyncThunk('category/addBooksAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        let data;
        if (payload.id) {
            data = await Api.patch(`items/books/${payload.id}`, { artitle: payload.artitle });
            const {
                book: { books }
            } = getState();
            const obj = await books.filter((book) => book.id == payload.id)[0];
            let arr = [];
            let newItem = [];
            let deleteItem = [];
            await payload.authors.forEach(async (book) => {
                arr = obj.author_id.filter((element) => element.authors_id.id == book.id);
                arr.length == 0 && newItem.push(book);
            });
            await obj.author_id.forEach(async (book) => {
                arr = payload.authors.filter((element) => book.authors_id.id == element.id);
                arr.length == 0 && deleteItem.push(book);
            });
            newItem.forEach(async (element) => {
                await Api.post('items/books_authors', { authors_id: element.id, books_id: data.data.data.id });
            });
            deleteItem.forEach(async (element) => {
                await Api.delete(`items/books_authors/${element.id}`);
            });
        } else {
            data = await Api.post('items/books', { artitle: payload.artitle });
            payload.authors.forEach(async (element) => {
                const dataf = await Api.post('items/books_authors', { books_id: data.data.data.id, authors_id: element.id });
            });
        }
        dispatch(fetchBooksAction());
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const deleteBooksAction = createAsyncThunk('saying/deleteBooksAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        const { data } = await Api.delete(`items/books/${payload.id}`);
        payload.author_id.forEach(async (element) => {
            await Api.delete(`items/books_authors/${element.id}`);
        });
        dispatch(fetchBooksAction());

        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const bookSlice = createSlice({
    name: 'book',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchBooksAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [fetchBooksAction.fulfilled]: (state, { payload }) => {
            return { ...state, books: payload, loading: false };
        },
        [fetchBooksAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [addBooksAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [addBooksAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [addBooksAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [deleteBooksAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [deleteBooksAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [deleteBooksAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        }
    }
});

// Action creators are generated for each case reducer function
// export const {} = bookSlice.actions;

export default bookSlice.reducer;
