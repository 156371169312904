import Api from '../../api/Api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    authors: [],
    message: null,
    loading: false
};

export const fetchAuthorsAction = createAsyncThunk('author/fetchAuthorsAction', async (_, { getState, dispatch, rejectWithValue }) => {
    try {
        // const {
        //     eventsReducer: { value }
        // } = getState();
        // console.log('value', value);
        const { data } = await Api.get('items/authors');

        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const addAuthorsAction = createAsyncThunk('author/addAuthorsAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        if (payload.id) {
            await Api.patch(`items/authors/${payload.id}`, payload);
        } else {
            await Api.post('items/authors', payload);
        }
        dispatch(fetchAuthorsAction());
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const deleteAuthorsAction = createAsyncThunk(
    'author/deleteAuthorsAction',
    async (payload, { getState, dispatch, rejectWithValue }) => {
        try {
            await Api.delete(`items/authors/${payload.id}`);

            dispatch(fetchAuthorsAction());
            return data.data;
        } catch (error) {
            const message = error.response && error.response.data.message ? error.response.data.message : error.message;
            if (message) {
                throw error;
            }

            return rejectWithValue(message);
        }
    }
);
export const authorSlice = createSlice({
    name: 'author',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAuthorsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [fetchAuthorsAction.fulfilled]: (state, { payload }) => {
            return { ...state, authors: payload, loading: false };
        },
        [fetchAuthorsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [addAuthorsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [addAuthorsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [addAuthorsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [deleteAuthorsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [deleteAuthorsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [deleteAuthorsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        }
    }
});

// Action creators are generated for each case reducer function
// export const {} = authorSlice.actions;

export default authorSlice.reducer;
