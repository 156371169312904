// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'الصفحات',
    caption: 'الصفحات الرئسية',
    type: 'group',
    children: [
        // {
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: icons.IconKey,

        //     children: [
        {
            id: 'others',
            title: 'أخرى',
            type: 'item',
            url: '/main/others'
        },
        {
            id: 'events',
            title: 'الأحدات',
            type: 'item',
            url: '/main/events'
        },
        {
            id: 'sayings',
            title: 'الأقوال',
            type: 'item',
            url: '/main/sayings'
        }
        // {
        //     id: 'login3',
        //     title: 'Login',
        //     type: 'item',
        //     url: '/pages/login/login3',
        //     target: true
        // },
        // {
        //     id: 'register3',
        //     title: 'Register',
        //     type: 'item',
        //     url: '/pages/register/register3',
        //     target: true
        // }
        //     ]
        // }
    ]
};

export default pages;
