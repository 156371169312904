import Api from '../../api/Api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    tags: [],
    message: null,
    loading: false
};

export const fetchTagsAction = createAsyncThunk('tag/fetchTagsAction', async (_, { getState, dispatch, rejectWithValue }) => {
    try {
        // const {
        //     eventsReducer: { value }
        // } = getState();
        // console.log('value', value);
        const { data } = await Api.get('items/tags');
        console.log('fetchTagsAction', data.data);

        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const addTagsAction = createAsyncThunk('tag/addTagsAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        if (payload.id) {
            await Api.patch(`items/tags/${payload.id}`, payload);
        } else {
            await Api.post('items/tags', payload);
        }
        dispatch(fetchTagsAction());
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const deleteTagsAction = createAsyncThunk('tag/deleteTagsAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        await Api.delete(`items/tags/${payload.id}`);

        dispatch(fetchTagsAction());
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchTagsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [fetchTagsAction.fulfilled]: (state, { payload }) => {
            return { ...state, tags: payload, loading: false };
        },
        [fetchTagsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [addTagsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [addTagsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [addTagsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [deleteTagsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [deleteTagsAction.fulfilled]: (state) => {
            return { ...state, loading: false };
        },
        [deleteTagsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        }
    }
});

// Action creators are generated for each case reducer function
// export const {} = authorSlice.actions;

export default tagSlice.reducer;
