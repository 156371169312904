import Api from '../../api/Api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    categories: [],
    message: null,
    loading: false
};

export const fetchCategoriesAction = createAsyncThunk('category/fetchCategoriesAction', async (_, { rejectWithValue }) => {
    try {
        const { data } = await Api.get('items/categories');
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const addCategoriesAction = createAsyncThunk(
    'category/addCategoriesAction',
    async (payload, { getState, dispatch, rejectWithValue }) => {
        try {
            if (payload.id) {
                await Api.patch(`items/categories/${payload.id}`, payload);
            } else {
                await Api.post('items/categories', payload);
            }
            dispatch(fetchCategoriesAction());
            return data.data;
        } catch (error) {
            const message = error.response && error.response.data.message ? error.response.data.message : error.message;
            if (message) {
                throw error;
            }

            return rejectWithValue(message);
        }
    }
);
export const deleteCategoriesAction = createAsyncThunk(
    'category/deleteCategoriesAction',
    async (payload, { getState, dispatch, rejectWithValue }) => {
        try {
            await Api.delete(`items/categories/${payload.id}`);

            dispatch(fetchCategoriesAction());
            return data.data;
        } catch (error) {
            const message = error.response && error.response.data.message ? error.response.data.message : error.message;
            if (message) {
                throw error;
            }

            return rejectWithValue(message);
        }
    }
);
export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCategoriesAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [fetchCategoriesAction.fulfilled]: (state, { payload }) => {
            return { ...state, categories: payload, loading: false };
        },
        [fetchCategoriesAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [addCategoriesAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [addCategoriesAction.fulfilled]: (state) => {
            return { ...state, loading: false };
        },
        [addCategoriesAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [deleteCategoriesAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [deleteCategoriesAction.fulfilled]: (state) => {
            return { ...state, loading: false };
        },
        [deleteCategoriesAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        }
    }
});

// Action creators are generated for each case reducer function
// export const {} = authorSlice.actions;

export default categorySlice.reducer;
