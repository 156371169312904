import Api from '../../api/Api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    events: [],
    message: null,
    loading: false
};

export const fetchEventsAction = createAsyncThunk('event/fetchEventsAction', async (text, { getState, dispatch, rejectWithValue }) => {
    try {
        let url = 'items/events?fields=*,category_id.*,book_id.*,tag_id.tag_id.*,tag_id.id';
        if (text) url += `&filter[artitle][rlike]=%${text}%`;

        const { data } = await Api.get(url);
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }
        return rejectWithValue(message);
    }
});
export const addEventsAction = createAsyncThunk('event/addEventsAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        let tags = payload.tags;
        delete payload.tags;
        let data;
        if (payload.id) {
            data = await Api.patch(`items/events/${payload.id}`, payload);
            const {
                event: { events }
            } = getState();
            const obj = await events.filter((event) => event.id == payload.id)[0];
            let arr = [];
            let newItem = [];
            let deleteItem = [];
            await tags.forEach(async (tag) => {
                arr = await obj.tag_id.filter((element) => element.tag_id.id == tag.id);
                arr.length == 0 && newItem.push(tag);
            });
            await obj.tag_id.forEach(async (tag) => {
                arr = await tags.filter((element) => tag.tag_id.id == element.id);
                arr.length == 0 && deleteItem.push(tag);
            });
            newItem.forEach(async (element) => {
                await Api.post('items/events_tags', { tag_id: element.id, events_id: data.data.data.id });
            });
            deleteItem.forEach(async (element) => {
                await Api.delete(`items/events_tags/${element.id}`);
            });
        } else {
            data = await Api.post('items/events', payload);
            await tags.forEach(async (element) => {
                await Api.post('items/events_tags', { tag_id: element.id, events_id: data.data.data.id });
            });
        }
        dispatch(fetchEventsAction());
        return data.data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});

export const deleteEventsAction = createAsyncThunk('event/deleteEventsAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        const { data } = await Api.delete(`items/events/${payload.id}`);
        await payload.tag_id.forEach(async (element) => {
            await Api.delete(`items/events_tags/${element.id}`);
        });
        dispatch(fetchEventsAction());

        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchEventsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [fetchEventsAction.fulfilled]: (state, { payload }) => {
            return { ...state, events: payload, loading: false };
        },
        [fetchEventsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [addEventsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [addEventsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [addEventsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [deleteEventsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [deleteEventsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [deleteEventsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        }
    }
});

// Action creators are generated for each case reducer function
// export const {} = eventSlice.actions;

export default eventSlice.reducer;
