// project imports
import config from 'config';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        openMenu: (state, action) => {
            state.isOpen = [action.payload];
        },
        setMenu: (state, action) => {
            state.opened = action.payload;
        },
        setStateFontFamily: (state, action) => {
            state.fontFamily = action.payload;
        },
        setStateBorderRadius: (state, action) => {
            state.borderRadius = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { openMenu, setMenu, setStateFontFamily, setStateBorderRadius } = layoutSlice.actions;

export default layoutSlice.reducer;
