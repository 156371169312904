import axios from 'axios';
// import { localStoragePrefix } from '../helpers/config';

// axios.defaults.baseURL = 'https://supportprophet.com/backend_api/public/support-prophet/';
axios.defaults.baseURL = 'https://supportprophet.com/api/public/support/';

axios.interceptors.request.use(
    async (config) => {
        const token = 'V3aT8tgdCvRJayU27MjNLPj4';
        // localStorage.getItem(localStoragePrefix + 'token');
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
