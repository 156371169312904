import { configureStore } from '@reduxjs/toolkit';
import authorReducer from './features/authorSlice';
import layoutReducer from './features/layout';
import categoryReducer from './features/categorySlice';
import tagReducer from './features/tagSlice';
import bookReducer from './features/bookSlice';
import sayingReducer from './features/sayingSlice';
import eventReducer from './features/eventSlice';

export const store = configureStore({
    reducer: {
        author: authorReducer,
        event: eventReducer,
        category: categoryReducer,
        book: bookReducer,
        tag: tagReducer,
        layout: layoutReducer,
        saying: sayingReducer
    }
});
