import Api from '../../api/Api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    sayings: [],
    message: null,
    loading: false
};

export const fetchSayingsAction = createAsyncThunk('saying/fetchSayingsAction', async (_, { getState, dispatch, rejectWithValue }) => {
    try {
        const { data } = await Api.get('items/sayings?fields=*,author_id.*,event_id.events_id.*,event_id.id');
        return data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const addSayingsAction = createAsyncThunk('category/addSayingsAction', async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
        let events = payload.events;
        delete payload.events;
        let data;
        if (payload.id) {
            data = await Api.patch(`items/sayings/${payload.id}`, payload);
            const {
                saying: { sayings }
            } = getState();
            const obj = await sayings.filter((saying) => saying.id == payload.id)[0];
            let arr = [];
            let newItem = [];
            let deleteItem = [];
            await events.forEach(async (event) => {
                arr = await obj.event_id.filter((element) => element.events_id.id == event.id);
                arr.length == 0 && newItem.push(event);
            });
            await obj.event_id.forEach(async (event) => {
                arr = await events.filter((element) => event.events_id.id == element.id);
                arr.length == 0 && deleteItem.push(event);
            });
            newItem.forEach(async (element) => {
                await Api.post('items/events_sayings', { events_id: element.id, sayings_id: data.data.data.id });
            });
            deleteItem.forEach(async (element) => {
                await Api.delete(`items/events_sayings/${element.id}`);
            });
        } else {
            data = await Api.post('items/sayings', payload);
            await events.forEach(async (element) => {
                await Api.post('items/events_sayings', { events_id: element.id, sayings_id: data.data.data.id });
            });
        }
        dispatch(fetchSayingsAction());
        return data.data.data;
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        if (message) {
            throw error;
        }

        return rejectWithValue(message);
    }
});
export const deleteSayingsAction = createAsyncThunk(
    'saying/deleteSayingsAction',
    async (payload, { getState, dispatch, rejectWithValue }) => {
        try {
            const { data } = await Api.delete(`items/sayings/${payload.id}`);
            payload.event_id.forEach(async (element) => {
                await Api.delete(`items/events_sayings/${element.id}`);
            });
            dispatch(fetchSayingsAction());

            return data.data;
        } catch (error) {
            const message = error.response && error.response.data.message ? error.response.data.message : error.message;
            if (message) {
                throw error;
            }

            return rejectWithValue(message);
        }
    }
);
export const sayingSlice = createSlice({
    name: 'saying',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchSayingsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [fetchSayingsAction.fulfilled]: (state, { payload }) => {
            return { ...state, sayings: payload, loading: false };
        },
        [fetchSayingsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [addSayingsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [addSayingsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [addSayingsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        },
        [deleteSayingsAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [deleteSayingsAction.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false };
        },
        [deleteSayingsAction.rejected]: (state, { error: { message } }) => {
            return { ...state, message: message, loading: false };
        }
    }
});

// Action creators are generated for each case reducer function
// export const {} = sayingSlice.actions;

export default sayingSlice.reducer;
